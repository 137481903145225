.app {
  background-color: #282c34;
  display: flex;
  font-size: calc(10px + 1.75vw);
  color: white;
  height: 100%;
}

.app-height-check {
  position: absolute;
  visibility: hidden;
  height: 100vh;
  width: 100%;
}

.app-background {
  width: 100vw;
  display: inline-block;
}

.app-content {
  width: 100vw;
  height: 100%;
  display: inline-block;
  margin-left: -100vw;
  overflow-y: scroll;
}
